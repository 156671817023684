.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 32px;

  &__full {
    height: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
