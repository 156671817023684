.wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 16px;
}

.title {
  font-weight: 600;
}

.stats {
  display: flex;

  &__icon {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
