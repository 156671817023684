.status {
  font-weight: 600;
  color: #12B76A;

  &__error {
    color: red;
  }
}

.value {
  font-weight: 500;
}
