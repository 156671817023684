.auth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: #fff;
  &:before {
    position: absolute;
    top: 0;
    right: auto;
    left: 0;
    z-index: 1;
    content: '';
    display: block;
    width: 35%;
    height: 100vh;
    margin: 0 auto;
    background: #fff;
    background-size: cover;
  }
}
