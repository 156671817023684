@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

.codeBlock {
  position: relative;
  border-radius: 8px !important;
  overflow: hidden;
  background:  #1D2229;
  padding: 0 0 80px 0 ;
  margin: 30px 0;
  line-height: 24px;
  & span, & code {
    font: {
      size: 16px !important;
      family: 'Fira Code', monospace !important;
    };
    color: #ffffff !important;
    background: #1D2229 !important;
    white-space: pre-wrap !important;
  }
}
.copyButton {
  position: absolute !important;
  z-index: 1 !important;
  top: auto !important;
  bottom: 24px !important;
  left: 16px !important;
  right: 16px !important;
  background: #282F38 !important;
  border: none !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-items: center !important;
  align-items: center !important;
  justify-content: center !important;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 !important;

  &:hover {
    background: #3B4654FF !important;
    color: #98A2B3 !important;
  }
  & span {
    background: transparent !important;
    margin: 0 0 0 10px;
    height: 48px;
    line-height: 48px;
    font: {
      size: 16px !important;
      weight: 500 !important;
    };

    &.copy {
      color: #98A2B3 !important;
    }
  }
}
