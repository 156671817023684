.wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 499px;
  height: 100vh;
  background: #fff;
}
.copyrights {
  position: absolute;
  bottom: 17px;
  color: transparentize(rgb(0,0,0),.55);
}
.logo {
  margin: 0 0 48px;
  text-align: center;
}
.signInButton {
  display: block !important;
  text-align: center !important;
  width: 360px !important;
  background: #141414 !important;
  color: #fff !important;
  margin: 0 0 23px !important;
  font: {
    size: 16px !important;
    weight: 700 !important;
  };
}

.formItem {
    position: relative;
    z-index: 2;
    display: block;
    width: 360px;
    height: 40px;
    min-height: 40px;
    & input {
    text-align: center;
  }
    & div {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-items: center;
      width: 35px;
      height: 40px;
    }
  &.lastItem {
    margin: 0 0 28px;
  }
}


