.confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 210px;
}
.text {
  line-height: 28px;
  margin: 24px 0 0;
  text-align: center;
  font: {
    size: 20px;
    weight: 700;
  };
}
