.statsItem {
  display: flex;
  width: 100%;
  height: 20px;
  align-items: center;
  justify-content: flex-start;

  & svg {
    width: 14px;
    height: 14px;
  }
  & span {
    color: #595959;
    line-height: 20px;
    margin: 0 0 0 5px;
    font: {
      size: 14px;
      weight: 400;
    };
  }
}
