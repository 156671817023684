.project {
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: #F5F5F5;
}
.projectItem {
  width: 50%;
  min-width: 699px;
  padding: 27px 35px;
  background: #fff;
  border-radius: 16px;
  &:nth-child(1) {
    margin: 0 12px 0 0;
  }
  &:nth-child(2) {
    margin: 0 0 0 12px;
  }
}
.statInfo {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 0 24px 0;
}
.cases {
  white-space: nowrap;
  margin: 0 25px 0 0;
  font:  {
    size: 16px;
    weight: 700;
  };
}
.statItems {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto !important;
  min-width: 60%;
}
.statItem {
  width: auto !important;
  margin: 0 15px 0 0;
}
.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
