.titleBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 0 56px;
}
.title {
  width: 100%;
  min-height: 32px;
  margin: 0 0 8px;
  line-height: 32px;
  color: #000;
  text-align: center;
  font: {
    size: 24px;
    weight: normal;
  };
}
.description {
  width: 100%;
  min-height: 24px;
  line-height: 24px;
  margin: 0 !important;
  color: #000;
  text-align: center;
  font: {
    size: 16px;
    weight: normal;
  };
}
