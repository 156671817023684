body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

* {
  box-sizing: border-box;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

:global {
  .pageContent {

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #111;
      width: 3px;
    }

    &::-webkit-scrollbar {
      width: 3px;
      padding-right: 5px;
    }

    &.empty {
      margin: 78px 0 0;
    }
  }

  .clickOutSide {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    border: none !important;

    &.dark {
      background: transparentize(rgb(0, 0, 0), .55);
    }
  }

  .faceAvatar {
    width: 32px;
    height: 32px;
  }

  .avatar {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    & span {
      color: #fff;
      border-radius: 50%;
      font-weight: 800;

      & span {
        margin: 0 !important;
      }
    }

    & img {
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .modal {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}
