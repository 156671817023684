.header {
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  background: #121115;
}
.rightBlock {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 8px;
}

.question {
  width: 24px;
  height: 24px;
  & svg {
    color: #FFFFFF;
    width: 24px;
    height: 24px;
  }
}

.headerMenu {
  display: flex;
}

.menu {
  z-index: 2 !important;
  width: 104px;
  top: 5px !important;
  left: -20px !important;
  background: #FFFFFF;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(220, 64, 64, 0.05);

  & ul {
    & li {
      &:hover {
        background: #272727 !important;
        & button {
          & span {
            color: #fff !important;
          }
        }
      }
      &.isDisabled {
        cursor: none;
        pointer-events: none;
        background: #272727 !important;
        & button {
          & span {
            color: #fff !important;
          }
        }
      }
    }
  }
}
.menuItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-items: center;
  height: 40px;
  padding: 0 !important;
  margin: 0 0 17px;
}
.menuButton {
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
  text-align: right !important;
  line-height: 22px !important;
  font: {
    size: 14px !important;
  };
}
.homeButton {
  position: relative;
  top: 2px;
  margin: 0 0 0 34px;
  height: 45px !important;
  background: #272727 !important;
  border-radius: 8px 8px 0 0 !important;
  fill: #fff !important;

  &:hover {
    background: #F5F5F5 !important;
    fill: #1A1A1A !important;
  }

}
.headerProjectButtons {
  position: relative;
  top: 4px;
  display: flex;
  width: 100%;
  height: 49px;
  margin: 0 0 0 10px;
  overflow: hidden;
}
.leftBock {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  overflow: hidden;
  height: 56px;
}
.logo {
  display: flex;
  align-items: center;
  width: 89px;
  height: 56px;
}
.isActive {
  cursor: none !important;
  pointer-events: none !important;
  background: #F5F5F5 !important;
  fill: #1A1A1A !important;
}
