@import "src/styles/mixins";

.container {
  position: relative;
  padding: 0 32px 32px;
  overflow-y: auto;

  &__full {
    height: 100%;
  }
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  justify-content: space-between;
  gap: 8px;

  @include respond-to('tabletSmall') {
    flex-direction: column;
    align-items: flex-start;
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
