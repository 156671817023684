.notificationAlert {
  position: absolute;
  top: 115px;
  width: 360px;
  height: 40px;
  background: #F6FFED;
  border: 1px solid #B7EB8F;
  border-radius: 2px;
  text-align: center;
}
.text {
  height: 40px;
  line-height: 40px;
  color: #135200;
  font: {
    size: 14px;
  };
}
