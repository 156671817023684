.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto !important;
  min-width: 40%;
}
.upload {
  margin: 0 8px 0 0 !important;
  background: #141414 !important;
  & span {
    color: #fff !important;
  }
  &:hover {
    background: #141414 !important;
    & span {
      color: #fff !important;
    }
  }
}
