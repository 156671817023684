.statItems {
  display: flex;
  flex-direction: row;
}
.statsRow {
  display: flex;
  flex-direction: column;
  &:nth-last-child(1) {
    margin: 0 0 0 13px;
  }
}
