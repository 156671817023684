.projectItem {
  width: 50%;
  min-width: 699px;
  padding: 27px 35px;
  background: #fff;
  border-radius: 16px;
  &:nth-child(1) {
    margin: 0 12px 0 0;
    overflow-y: scroll;
  }
  &:nth-child(2) {
    margin: 0 0 0 12px;
  }
}
.statInfo {
  display: flex;
  justify-content:space-between;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0 0 24px 0;
}
