.withOutIcon {
  align-items: center !important;
  :global {
    .ant-list-item-meta-avatar {
      position: relative;
      color: #fff;
      text-align: center;
      font: {
        size: 14px;
        weight: 800;
      };
    }
  }
  & img {
    position: absolute;
    left: 0;
    z-index: 2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
}

.withOutBorder {
  width: 431px;
  & li {
    border: none !important;
  }
}

.alignRight {
  text-align: right;
}

.redText {
  color: #FF4D4F;
}
.moreIcon {
  width: 24px;
  height: 24px;
  color: #8C8C8C;
  & span {
    width: 100%;
    height: 100%;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
}