.actionPanel {
  position: relative;
  width: 100%;
}

.newButtonPanel {
  display: flex;
  align-items: center;
  padding: 16px 32px 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  & span {
    color: #262626;
  }
}

.newButton {
  position: relative !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  flex-wrap: nowrap !important;
  width: fit-content !important;
  border: 1px solid #262626 !important;
  margin: 0 0 0 25px;

  & span {
    line-height: 20px;
    font: {
      weight: 700;
      size: 14px;
    };
  }
  & svg {
    width: 13px;
    height: 13px;
    margin: -2px 11px 0 0;
    & path {
      fill: #000;
    }
  }
}
