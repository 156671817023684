.name {
  display: flex;
  align-items: center;

  &__icon {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.rowContent {
  padding: 0 16px 16px 64px;

  h3:first-child {
    margin-top: 0;
  }
}
