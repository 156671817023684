.title {
  font: {
    size: 24px;
    weight: 600;
  };
}
.content{
  height: 100vh !important;
  padding-bottom: 250px !important;
  overflow-y: scroll !important;
}
.contentWrap {
  width: 709px !important;
  height: 100vh !important;
}
.text {
  font: {
    size: 18px;
    weight: 400;
  };
  line-height: 32px;
}
