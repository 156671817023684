.graph  {
  display: block;
}
.graphWrap {
  height: 700px;
}
.line {
  stroke: #6C6C6C;
  stroke-width: 1px;
  margin: 2px;
}
.hiddenLine {
  stroke: #d1dbc5;
  stroke-width: 2px;
  margin: 2px;
}
.mainLine {
  stroke: #6C6C6C;
  stroke-width: 4px;
  margin: 2px;
}
.titleBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.title {
  font-family: 'Syne';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #595959;
  padding-bottom: 20px;
}
.graphField {
  min-height: 700px;
  height: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 15.5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border: solid 3px #ededed;
  background-image: linear-gradient(rgba(244, 244, 250, .8) 1px, transparent 0),
    linear-gradient(#f4f4fa 1px, transparent 0),
    linear-gradient(90deg, rgba(244, 244, 250, .8) 1px, transparent 0),
    linear-gradient(90deg, #f4f4fa 1px, transparent 0),
    linear-gradient(transparent 3px, #fff 0, #fff 94px, transparent 0),
    linear-gradient(90deg, #f4f4fa 3px, transparent 0, transparent 94px, #f4f4fa 0);
  background-size: 24px 24px, 96px 96px, 24px 24px, 96px 96px, 96px 96px, 96px 96px;
  // fonts
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #455a64;
  position: relative;
}
.tooltip {
  opacity: 0;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 65px;
  padding: 7px;
  background: #EBEBEB;
  box-sizing: border-box;
  border-radius: 4px;
  pointer-events: none;
  & p {
    text-align: left;
    position: relative;
    margin: 1px 36px 0px 7px;
  }
  & p + p {
    font-size: 11px;
  }
  & div {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    width: 80%;
    align-self: flex-start;
  }
  & svg {
    height: 12px;
    margin-right: 2px;
    top: 2px;
    position: relative;
  }
}
.nodeText {
  background-color: #EBEBEB;
  border-radius: 4px;
  padding: 2px 8px;
  height: 15px;
  width: 100px;
}
.edgeLabel {
  font-weight: 700;
  font-size: 10px;
  text-anchor: middle;
}