.rememberBlock {
  position: relative;
  top: 3px;
  display: flex;
  justify-content: space-between;
  width: 360px;
  height: 20px;
  margin: 0 0 22px;
}
.forgot {
  height: 20px !important;
  padding: 0 !important;
  line-height: 20px !important;
  color: #141414;
  &:hover{
    background: transparent !important;
  }
  font: {
    size: 16px !important;
    weight: 500 !important;
  };
  & span {
    line-height: 20px !important;
    color: #141414;
  }
}
