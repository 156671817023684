.titleBlock {
  display: flex;
  align-items: center;
  padding: 0 32px;
}

.pageTitle {
  padding: 0 !important;
}

.header {
  height: 56px;
}

.content {
  flex-grow: 1;
  background: #F5F5F5;
  position: relative;
}
