.userForm {
  display: flex;
  flex-direction: column;
  width: 360px;
}
.save {
  width: 116px;
  background: #F5F5F5 !important;
  border: 1px solid #D9D9D9  !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016)  !important;
  border-radius: 2px !important;
  & span {
    font: {
      size: 16px;
      weight: bold;
    };
    color: transparentize(rgb(0,0,0), .75) !important;
  }
  &:hover {
    border: 1px solid  transparentize(rgb(0,0,0), .25)  !important;
    & span {
      color: transparentize(rgb(0,0,0), .25) !important;
    }
  }
}
