.apiKeys {
  display: block;
  padding: 32px 0 0;
}
.table {
  width: 605px;
}
th {
  padding: 13px 12px 12px !important;
  height: 48px;
  background: #F5F5F5;
}

td {
  padding: 13px 16px 12px !important;
  height: 48px;
}
