.panels {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.link {
  text-decoration: none;
}

.panel {
  min-height: 148px;
  background: #fff;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  cursor: pointer;
}

.stats {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 90px;
  padding: 9px 16px;
  background: url("../../../assets/images/chart-dynamic-clay.jpg") no-repeat left bottom;
    background-size: 100px 90px;
  border-bottom: 1px solid #F0F0F0;
}
.statsInfo {
  display: flex;
  flex-direction: column;
  width: auto;
}
