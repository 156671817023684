.manual {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.item {
  position: relative;
  width: 318px;
  &:after {
    position: absolute;
    top: 102px;
    left: auto;
    right: -140px;
    content: "";
    display: block;
    width: 87px;
    height: 33px;
    background: url("../../../assets/images/dashboardArrow.jpg") no-repeat center center;
  }
  &:nth-last-child(1) {
    &:after {
      display: none;
    }
  }
}
.text {
  width: 100%;
  height: 24px;
  line-height: 24px;
  margin: 36px 0 0 !important;
  color: #000;
  text-align: center;
  font: {
    size: 16px;
    weight: 500;
  };
}
