.socialButton {
  width: 22px;
  height: 24px;
  margin: 0 12px 0 0;
  background: transparent;
  border: none;
  cursor: pointer;
}
.quickSign {
  margin: 0 16px 0 0;
  color: transparentize(rgb(0,0,0), .15);
  font: {
    size: 16px;
  };
}
