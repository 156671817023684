.signUpLink {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 360px;
  & span {
    color: #141414;
  }
}
.isAlone {
  justify-content: flex-start;
}
.textButton {
  &:hover{
    background: transparent !important;
  }
  padding: 0 !important;
  font: {
    size: 18px !important;
    weight: 500 !important;
  };
}
