.signUpForm {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 1440px;
  min-width: 1440px;
  height: 100vh;
  min-height: 900px;
  margin: 0 auto;
  overflow-y: hidden;
  background: transparent;
  &:after {
    position: relative;
    z-index: 0;
    content: '';
    display: block;
    width: 1000px;
    height: 100vh;
    margin: 0 auto;
    background: url('../../../assets/images/auth.jpg') right bottom no-repeat;
    background-size: initial;
  }
}
.wrap {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 499px;
  height: 100vh;
  background: #fff;
}
.form {
  position: relative;
  min-height: 469px !important;
}
.copyrights {
  position: absolute;
  bottom: 17px;
  color: transparentize(rgb(0,0,0),.55);
}
.logo {
  margin: 0 0 105px;
  text-align: center;
}
.signInLogo {
  margin: 0 0 48px;
}
