.profile {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.profileAvatar {
  width: 88px;
  height: 88px;
  margin: 27px 0 32px;
  & span {
    margin: 27px 0 32px;
    line-height: 88px !important;
    font: {
      weight: 800;
      size: 51px;
    };
  }
  & img {
    top: -2px;
  }
  & img {
    display: none;
  }
}
.avatarAbbr {
  color: #fff;
  font: {
    weight: 800;
    size: 51px;
  };
}
