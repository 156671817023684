.customTab {
  width: 300px;
  height: 50px;
  background: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 0 0 15px;
  &:hover {
    background: #F2F4F7;
  }
  &.isActiveTab {

    background: #111;
    & .title {
      color: #fff;
    }
  }

}
.title {
  color: #111;
  font: {
    size: 16px;
    weight: 500;
  };
}
.icon {
  margin: 0 8px 0 0;
}
