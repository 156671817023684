.uploadButton {
  display: flex !important;
  justify-items: center !important;
  align-items: center !important;
  justify-content: center !important;
  width: 167px !important;
  height: 56px !important;
  background: #101828 !important;
  border-radius: 40px !important;
  border: none !important;
  margin: 0 auto !important;
  &:hover {
    background: #3B4654FF !important;
  }
}
.uploadButtonText {
  font: {
    size: 16px;
    weight: 500;
  };
  line-height: 56px;
  color: #ffffff;
  margin: 0 0 0 10px;
}
.fileUploadHidden {
  cursor: pointer;
  height: 0;
  left: auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: 30px;
  width: 190px;
  z-index: 1;
}
