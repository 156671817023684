.info {
  position: relative;
  display: flex;
  align-items: center;

  padding: 6px 16px;
}
.panelTitle {
  margin: 0;
  color: #000000;
  line-height: 22px;
  font: {
    size: 14px;
    weight: bold;
  };
  & a {
    color: #000000 !important;
  }
}
.panelInfo {
  margin: 0;
  color: #8C8C8C;
  line-height: 20px;
  font: {
    size: 12px;
  };
}
.text {
  width: calc(100% - 10px);
  text-decoration: none;
}
.overlay {
  z-index: 1000;
}
.moreIcon {
  width: 24px;
  height: 24px;
  color: #8C8C8C;
  & span {
    width: 100%;
    height: 100%;
    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
